export function hamburger() {
  const hamburger = document.querySelector('.js-hamburger');
  const headerNav = document.querySelector('.js-headerNav');

  hamburger.addEventListener('click', () => {
    if (hamburger.classList.contains('is-active')) {
      hamburger.classList.remove('is-active');
      headerNav.classList.remove('is-view');
    } else {
      hamburger.classList.add('is-active');
      headerNav.classList.add('is-view');
    }
  });
}
